import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { StrictMode, startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { getInitialNamespaces } from "remix-i18next/client";
import { z } from "zod";
import { zodI18nMap } from "zod-i18n-map";
import i18n from "~/lib/i18n/config";

Sentry.init({
	dsn: window.ENV.SENTRY_DSN,
	enabled: !!window.ENV.APP_ENV && window.ENV.APP_ENV !== "local",
	environment: window.ENV.APP_ENV,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	tracesSampleRate: 1,

	integrations: [
		Sentry.browserTracingIntegration({
			useEffect,
			useLocation,
			useMatches,
		}),
		Sentry.replayIntegration(),
	],
});

async function hydrate() {
	await i18next
		.use(initReactI18next)
		.use(LanguageDetector)
		.init({
			...i18n,
			ns: getInitialNamespaces(),
			detection: {
				order: ["htmlTag"],
				caches: [],
				lookupCookie: "lng",
			},
		});
	z.setErrorMap(zodI18nMap);

	startTransition(() => {
		hydrateRoot(
			document,
			<I18nextProvider i18n={i18next}>
				<StrictMode>
					<RemixBrowser />
				</StrictMode>
			</I18nextProvider>,
		);
	});
}

if (window.requestIdleCallback) {
	window.requestIdleCallback(hydrate);
} else {
	window.setTimeout(hydrate, 1);
}
